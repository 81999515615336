import React from "react";
import { LogoutAction } from "../../redux/actions/AuthActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


export default function Logout({ signOut }) {
  const nav = useNavigate();
  const dispatch = useDispatch();
  console.log("Logout.js: signOut: ", signOut);
  React.useEffect(() => {
    dispatch(LogoutAction({ signOut }));
    nav("/");
  }, []);
}