import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// Auth routes
import Logout from './components/Auth/Logout';

// Other routes
import Home from './components/Pages/Main';
import LocationSelection from './components/Displays/LocationSelection';
import { setAllUserInfo } from './redux/reducers/UserReducer';
import { setAllLocationInfo } from './redux/reducers/LocationReducer';
import {
  setAllCompanyInfo,
  resetCompanyState,
} from './redux/reducers/CompanyReducer';

import { GetCompanyService } from './services/CompanyServices';
import { GetLocationService } from './services/LocationsServices';

import { resetLocationState } from './redux/reducers/LocationReducer';
import { resetUserState } from './redux/reducers/UserReducer';
import { resetPageState } from './redux/reducers/PageReducer';
import { resetTraineesState } from './redux/reducers/TraineesReducer';
import { resetLanguageState } from './redux/reducers/LanguageReducer';
import { getAllLanguagesAction } from './redux/actions/LanguageActions';
import { setScreen } from './redux/reducers/PageReducer';

import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import outputs from './amplify_outputs.json';

import { fetchUserAttributes } from '@aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import '@aws-amplify/ui-react/styles.css';

Amplify.configure(outputs);

function AuthenticatedRoutes({ user, signOut }) {
  const history = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Exit early if the user is null or undefined
    if (!user) {
      return;
    }

    let isCancelled = false;

    const fetchData = async () => {
      try {
        const attributes = await fetchUserAttributes();

        if (isCancelled) return;

        // Clear any previous states just in case
        dispatch(resetCompanyState());
        dispatch(resetLanguageState());
        dispatch(resetLocationState());
        dispatch(resetPageState());
        dispatch(resetTraineesState());
        dispatch(resetUserState());

        const formatUser = Object.entries(attributes).reduce(
          (acc, [key, value]) => {
            if (key.startsWith('custom:')) {
              acc[key.replace('custom:', '')] = value;
            } else {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );

        // Set user and language info
        dispatch(setAllUserInfo(formatUser));
       // console.log('formatUser:', formatUser);
        dispatch(getAllLanguagesAction());

        // If the user's location_id contains a comma, navigate to location selection screen
        if (formatUser.location_id?.includes(',')) {
          setTimeout(() => {
            history('/location-selection');
          }, 1);
          return;
        }

        // Fetch location and company data
        const [locationInfo, companyInfo] = await Promise.all([
          GetLocationService(`id/${formatUser.location_id}`),
          GetCompanyService(`id/${formatUser.company_id}`),
        ]);

        console.log('locationInfo:', locationInfo);
        console.log('companyInfo:', companyInfo);

        if (isCancelled) return;

        // Validate that locationInfo.data and companyInfo.data exist
        if (!locationInfo || !companyInfo) {
          console.error('Location or company data is missing:', {
            locationInfo,
            companyInfo,
          });
          return;
        }

        // Set company and location info
        dispatch(setAllCompanyInfo(companyInfo));
        dispatch(setAllLocationInfo(locationInfo));

        // Navigate away
        dispatch(setScreen('training-lobby'));
        setTimeout(() => {
          history('/');
        }, 1);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };

    fetchData();

    return () => {
      isCancelled = true; // Cleanup to prevent state updates after logout
    };
  }, [user]);

  return (
    <Routes>
      <Route path="/logout" element={<Logout signOut={signOut} />} />
      <Route path="/" element={<Home />} />
      <Route path="/location-selection" element={<LocationSelection />} />
      <Route path="/*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default function AppRoutes() {
  return (
    <Authenticator hideSignUp className="body-decorator">
      {({ signOut, user }) => (
        <AuthenticatedRoutes user={user} signOut={signOut} />
      )}
    </Authenticator>
  );
}
