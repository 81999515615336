import React from 'react';
import { useSelector} from 'react-redux';
import TrainingDisplay from '../Displays/TrainingDisplay';
import VideoDisplay from '../Displays/VideoDisplay';
import ReviewDisplay from '../Displays/ReviewDisplay';
import Loader from '../Loader';
import LocationSelectionDisplay from '../Displays/LocationSelection';

// Components
import '../../assets/style/components/buttons.scss'
import '../../assets/style/components/centered-card.scss'
import '../../assets/style/components/forms.scss'
import '../../assets/style/components/loader.scss'
import '../../assets/style/components/review-card.scss'
// Pages
import '../../assets/style/pages/agreement.scss'
import '../../assets/style/pages/finished.scss'
import '../../assets/style/global-styles.scss'
import '../../assets/style/pages/is-this-you.scss'
import '../../assets/style/pages/new-trainee-register.scss'
import '../../assets/style/pages/review-training-info.scss'
import '../../assets/style/pages/signature-agreement.scss'
import '../../assets/style/pages/start-training.scss'
import '../../assets/style/pages/training-lobby.scss'
import '../../assets/style/pages/video-section.scss'


/*
main displays:
auth screens
    - login
    - logout
    - forgot password

signup
    - checkin/checkout
    - welcome
    - is this you
    - trainee register form
    - lobby
video
    - main video
    - loader area (will be handled with loading state)
    - popout panel (move out when video is finished)
review
    - start employee list
    - agreement form
    - signature box
    - training finished screen
*/

//const display = 'training'; // this will be set based on state

function renderCorrectComponent(current_display){
    switch(current_display){
        case 'training':
            return <TrainingDisplay/>;
        case 'video':
            return <VideoDisplay/>;
        case 'review':
            return <ReviewDisplay/>;
        case 'location-selection':
            return <LocationSelectionDisplay />;
        default:
            return 'something went wrong - single page';
    }
}



// main component
function Main(){
    const current_display = useSelector((state) => state.page.display)
    
        // return veiw to be rendered
        return (
            <>
                { renderCorrectComponent(current_display) }
            </>
        )
    }
    
export default Main;

