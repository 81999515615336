import React, { useState, useEffect } from 'react';

const LoadingButton = ({
  initialLabel,
  onSubmit,
  type = 'button',
  loadingLabel = 'Loading...', // Optional prop
  extraClasses = '',
  disabled = false, // Add explicit disabled prop
}) => {
  const [label, setLabel] = useState(initialLabel);
  const [loading, setLoading] = useState(false);

  // This effect ensures that the label updates are reflected in the UI
  // Use effect to update the label when the initialLabel prop changes
  useEffect(() => {
    if (!loading) {
      setLabel(initialLabel);
    }
  }, [initialLabel, loading]); // Run effect when initialLabel or loading state changes

  const handleClick = async (e) => {
    e.preventDefault(); // Prevent form submission if handled here

    setLoading(true);
    setLabel(loadingLabel);

    try {
      await onSubmit();
      // Keep loading state active until navigation
    } catch (error) {
      console.error('Error during submission:', error);
      setLoading(false); // Only reset on error
    }
  };

  // Combine classes and handle disabled state
  const buttonClasses = `button button-blue ${extraClasses} ${
    loading || disabled ? 'disabled opacity-50 cursor-not-allowed' : ''
  }`.trim();

  return (
    <button
      className={buttonClasses}
      disabled={loading || disabled}
      onClick={handleClick}
      type={type}
      aria-disabled={loading || disabled}
    >
      {label}
    </button>
  );
};

export default LoadingButton;
